// src/components/ContactsPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useAuthToken from '../hooks/useAuthToken'; // same hook used in App.js
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  Box,
  Button,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Chip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ContactsPage = () => {
  const token = useAuthToken(); // same hook used in App.js

  const [contacts, setContacts] = useState([]);
  const [agents, setAgents] = useState([]);

  // Sorting
  const [sortField, setSortField] = useState('first_name');
  const [sortDirection, setSortDirection] = useState('asc');

  // Create form
  const [newContact, setNewContact] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    contact_preference: '',
    company: '',
    role: '',
    context: '',
    agent_ids: [],
  });

  // Edit dialog
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  // Snackbar
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // On mount/token change, fetch data
  useEffect(() => {
    if (token) {
      fetchContacts(token);
      fetchAgents(token);
    }
  }, [token]);

  // ----------------------------------------------------------------
  // fetchContacts
  // ----------------------------------------------------------------
  const fetchContacts = async (authToken) => {
    try {
      const res = await axios.get(`${API_BASE_URL}/contacts/`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setContacts(res.data.contacts || []);
    } catch (err) {
      console.error('Error fetching contacts:', err);
      showSnackbar('Failed to fetch contacts.', 'error');
    }
  };

  // ----------------------------------------------------------------
  // fetchAgents
  // ----------------------------------------------------------------
  const fetchAgents = async (authToken) => {
    try {
      const res = await axios.get(
        `${API_BASE_URL}/assistant/list_assistants_with_tools/`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      setAgents(res.data || []);
    } catch (error) {
      console.error('Error fetching agents:', error);
      showSnackbar('Failed to fetch agents.', 'error');
    }
  };

  // ----------------------------------------------------------------
  // Snackbar Helpers
  // ----------------------------------------------------------------
  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // ----------------------------------------------------------------
  // Sorting Logic
  // ----------------------------------------------------------------
  const handleSort = (field) => {
    const isAsc = sortField === field && sortDirection === 'asc';
    setSortField(field);
    setSortDirection(isAsc ? 'desc' : 'asc');
  };

  const sortedContacts = [...contacts].sort((a, b) => {
    let valA = a[sortField] || '';
    let valB = b[sortField] || '';
    if (typeof valA === 'string') valA = valA.toLowerCase();
    if (typeof valB === 'string') valB = valB.toLowerCase();

    if (valA < valB) return sortDirection === 'asc' ? -1 : 1;
    if (valA > valB) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  // ----------------------------------------------------------------
  // Create Contact
  // ----------------------------------------------------------------
  const handleCreateContact = async () => {
    if (!token) {
      showSnackbar('No auth token found. Please log in again.', 'error');
      return;
    }
    try {
      const payload = {
        first_name: newContact.first_name.trim() || null,
        last_name: newContact.last_name.trim() || null,
        phone_number: newContact.phone_number.trim() || null,
        email: newContact.email.trim() || null,
        contact_preference: newContact.contact_preference.trim() || null,
        company: newContact.company.trim() || null,
        role: newContact.role.trim() || null,
        context: newContact.context.trim() || null,
        agent_ids: newContact.agent_ids,
      };

      await axios.post(`${API_BASE_URL}/contacts/`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Refresh, reset form
      fetchContacts(token);
      setNewContact({
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        contact_preference: '',
        company: '',
        role: '',
        context: '',
        agent_ids: [],
      });
      showSnackbar('Contact created successfully!', 'success');
    } catch (err) {
      console.error('Error creating contact:', err);
      showSnackbar('Error creating contact.', 'error');
    }
  };

  // ----------------------------------------------------------------
  // Delete Contact
  // ----------------------------------------------------------------
  const handleDeleteContact = async (contactId) => {
    if (!token) {
      showSnackbar('No auth token found.', 'error');
      return;
    }
    if (!window.confirm('Are you sure you want to delete this contact?')) return;

    try {
      await axios.delete(`${API_BASE_URL}/contacts/${contactId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchContacts(token);
      showSnackbar('Contact deleted.', 'info');
    } catch (err) {
      console.error('Error deleting contact:', err);
      showSnackbar('Error deleting contact.', 'error');
    }
  };

  // ----------------------------------------------------------------
  // Edit Contact logic
  // ----------------------------------------------------------------
  const openEditDialog = (contact) => {
    setSelectedContact({ ...contact });
    setEditDialogOpen(true);
  };

  const closeEditDialog = () => {
    setEditDialogOpen(false);
    setSelectedContact(null);
  };

  const handleEditChange = (field, value) => {
    setSelectedContact((prev) => ({ ...prev, [field]: value }));
  };

  const handleSaveContact = async () => {
    if (!token) {
      showSnackbar('No auth token found.', 'error');
      return;
    }
    if (!selectedContact) return;

    try {
      const payload = {
        first_name: selectedContact.first_name?.trim() || null,
        last_name: selectedContact.last_name?.trim() || null,
        phone_number: selectedContact.phone_number?.trim() || null,
        email: selectedContact.email?.trim() || null,
        contact_preference: selectedContact.contact_preference?.trim() || null,
        company: selectedContact.company?.trim() || null,
        role: selectedContact.role?.trim() || null,
        context: selectedContact.context?.trim() || null,
        agent_ids: selectedContact.agent_ids || [],
      };

      await axios.put(
        `${API_BASE_URL}/contacts/${selectedContact.contact_id}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      closeEditDialog();
      fetchContacts(token);
      showSnackbar('Contact updated successfully!', 'success');
    } catch (err) {
      console.error('Error updating contact:', err);
      showSnackbar('Error updating contact.', 'error');
    }
  };

  // ----------------------------------------------------------------
  // Common Input SX for Dark Mode
  // (Labels, placeholders, and typed text in light colors)
  // ----------------------------------------------------------------
  const darkTextFieldSx = {
    mb: 2,
    mr: 2,
    '& .MuiOutlinedInput-root': {
      // typed text color
      color: '#fff',
      // default border
      '& fieldset': {
        borderColor: '#555',
      },
      // hover/focus border
      '&:hover fieldset': {
        borderColor: '#999',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#bbb',
      },
    },
    // label text color
    '& .MuiInputLabel-root': {
      color: '#aaa',
    },
    // label when focused
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#fff',
    },
    // placeholder text color
    '& .MuiOutlinedInput-root input::placeholder': {
      color: '#aaa',
      opacity: 1,
    },
  };

  // We'll use the same approach for the multi-select as well
  const darkSelectSx = {
    minWidth: 220,
    mb: 2,
    mr: 2,
    '& .MuiOutlinedInput-root': {
      color: '#fff',
      '& fieldset': {
        borderColor: '#555',
      },
      '&:hover fieldset': {
        borderColor: '#999',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#bbb',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#aaa',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#fff',
    },
  };

  // ----------------------------------------------------------------
  // Render
  // ----------------------------------------------------------------
  return (
    <>
      <Card sx={{ mb: 3, backgroundColor: '#1e1e1e', boxShadow: 3 }}>
        <CardHeader
          avatar={<Avatar sx={{ bgcolor: 'primary.main' }}>C</Avatar>}
          title={<Typography variant="h5">Contacts Management</Typography>}
          subheader={
            <Typography variant="body2" color="text.secondary">
              Manage individuals and businesses that your AI agents can contact.
            </Typography>
          }
        />
        <CardContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Here, you can add new contacts, import them in bulk via CSV,
            and configure which agents have permission to contact them.
          </Typography>

          {/* CREATE CONTACT FORM */}
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
              mb: 4,
              backgroundColor: '#2a2a2a',
              p: 2,
              borderRadius: 1,
            }}
          >
            <TextField
              label="First Name"
              variant="outlined"
              size="small"
              value={newContact.first_name}
              onChange={(e) =>
                setNewContact((prev) => ({ ...prev, first_name: e.target.value }))
              }
              sx={{ ...darkTextFieldSx, width: 150 }}
            />
            <TextField
              label="Last Name"
              variant="outlined"
              size="small"
              value={newContact.last_name}
              onChange={(e) =>
                setNewContact((prev) => ({ ...prev, last_name: e.target.value }))
              }
              sx={{ ...darkTextFieldSx, width: 150 }}
            />
            <TextField
              label="Phone Number"
              variant="outlined"
              size="small"
              value={newContact.phone_number}
              onChange={(e) =>
                setNewContact((prev) => ({ ...prev, phone_number: e.target.value }))
              }
              sx={{ ...darkTextFieldSx, width: 160 }}
            />
            <TextField
              label="Email"
              variant="outlined"
              size="small"
              value={newContact.email}
              onChange={(e) =>
                setNewContact((prev) => ({ ...prev, email: e.target.value }))
              }
              sx={{ ...darkTextFieldSx, width: 180 }}
            />
            <TextField
              label="Contact Pref."
              placeholder="sms, email, etc."
              variant="outlined"
              size="small"
              value={newContact.contact_preference}
              onChange={(e) =>
                setNewContact((prev) => ({ ...prev, contact_preference: e.target.value }))
              }
              sx={{ ...darkTextFieldSx, width: 120 }}
            />
            <TextField
              label="Company"
              variant="outlined"
              size="small"
              value={newContact.company}
              onChange={(e) =>
                setNewContact((prev) => ({ ...prev, company: e.target.value }))
              }
              sx={{ ...darkTextFieldSx, width: 120 }}
            />
            <TextField
              label="Role"
              variant="outlined"
              size="small"
              value={newContact.role}
              onChange={(e) => setNewContact((prev) => ({ ...prev, role: e.target.value }))
              }
              sx={{ ...darkTextFieldSx, width: 100 }}
            />
            <TextField
              label="Context"
              placeholder="Important notes..."
              variant="outlined"
              size="small"
              value={newContact.context}
              onChange={(e) => setNewContact((prev) => ({ ...prev, context: e.target.value }))
              }
              sx={{ ...darkTextFieldSx, width: 200 }}
            />

            {/* Multi-select for Agents */}
            <FormControl variant="outlined" size="small" sx={darkSelectSx}>
              <InputLabel>Agents Permitted to Contact</InputLabel>
              <Select
                multiple
                label="Agents Permitted to Contact"
                value={newContact.agent_ids}
                onChange={(e) =>
                  setNewContact((prev) => ({ ...prev, agent_ids: e.target.value }))
                }
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em style={{ color: '#777' }}>No agents selected</em>;
                  }
                  return (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {selected.map((id) => {
                        const a = agents.find((agent) => agent.assistant_id === id);
                        return (
                          <Chip
                            key={id}
                            variant="outlined"
                            label={a ? a.assistant_name : id}
                            size="small"
                            sx={{
                              backgroundColor: '#424242',
                              color: '#fff',
                              borderColor: '#fff',
                            }}
                          />
                        );
                      })}
                    </Box>
                  );
                }}
              >
                {agents.length === 0 ? (
                  <MenuItem disabled>
                    <em>No agents found</em>
                  </MenuItem>
                ) : (
                  agents.map((agent) => (
                    <MenuItem key={agent.assistant_id} value={agent.assistant_id}>
                      {agent.assistant_name || agent.assistant_id}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleCreateContact}
              sx={{ height: 40, alignSelf: 'flex-end' }}
            >
              Add Contact
            </Button>
          </Box>

          {/* Example CSV upload button */}
          <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
            <Button variant="contained" color="secondary" startIcon={<UploadFileIcon />}>
              Upload CSV
            </Button>
          </Box>

          {/* CONTACTS TABLE */}
          {sortedContacts.length === 0 ? (
            <Typography>No contacts yet.</Typography>
          ) : (
            <Box sx={{ mt: 2, overflowX: 'auto' }}>
              <Table sx={{ minWidth: 1000 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={sortField === 'first_name'}
                        direction={sortField === 'first_name' ? sortDirection : 'asc'}
                        onClick={() => handleSort('first_name')}
                      >
                        First Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortField === 'last_name'}
                        direction={sortField === 'last_name' ? sortDirection : 'asc'}
                        onClick={() => handleSort('last_name')}
                      >
                        Last Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortField === 'email'}
                        direction={sortField === 'email' ? sortDirection : 'asc'}
                        onClick={() => handleSort('email')}
                      >
                        Email
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Preference</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Context</TableCell>
                    <TableCell>Agents</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedContacts.map((contact) => {
                    const assignedAgents =
                      contact.agent_ids && contact.agent_ids.length > 0
                        ? contact.agent_ids
                            .map((id) => {
                              const found = agents.find((ag) => ag.assistant_id === id);
                              return found ? found.assistant_name : id;
                            })
                            .join(', ')
                        : 'None';

                    return (
                      <TableRow key={contact.contact_id} hover>
                        <TableCell>{contact.first_name || ''}</TableCell>
                        <TableCell>{contact.last_name || ''}</TableCell>
                        <TableCell>{contact.phone_number || ''}</TableCell>
                        <TableCell>{contact.email || ''}</TableCell>
                        <TableCell>{contact.contact_preference || ''}</TableCell>
                        <TableCell>{contact.company || ''}</TableCell>
                        <TableCell>{contact.role || ''}</TableCell>
                        <TableCell>{contact.context || ''}</TableCell>
                        <TableCell>{assignedAgents}</TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <IconButton
                              color="primary"
                              onClick={() => openEditDialog(contact)}
                              size="small"
                            >
                              <EditIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={() => handleDeleteContact(contact.contact_id)}
                              size="small"
                            >
                              <DeleteIcon fontSize="inherit" />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          )}
        </CardContent>
      </Card>

      {/* EDIT CONTACT DIALOG */}
      <Dialog open={editDialogOpen} onClose={closeEditDialog} maxWidth="md" fullWidth>
        <DialogTitle>Edit Contact</DialogTitle>
        {selectedContact && (
          <DialogContent dividers>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                label="First Name"
                variant="outlined"
                size="small"
                value={selectedContact.first_name || ''}
                onChange={(e) => handleEditChange('first_name', e.target.value)}
                sx={darkTextFieldSx}
              />
              <TextField
                label="Last Name"
                variant="outlined"
                size="small"
                value={selectedContact.last_name || ''}
                onChange={(e) => handleEditChange('last_name', e.target.value)}
                sx={darkTextFieldSx}
              />
              <TextField
                label="Phone Number"
                variant="outlined"
                size="small"
                value={selectedContact.phone_number || ''}
                onChange={(e) => handleEditChange('phone_number', e.target.value)}
                sx={darkTextFieldSx}
              />
              <TextField
                label="Email"
                variant="outlined"
                size="small"
                value={selectedContact.email || ''}
                onChange={(e) => handleEditChange('email', e.target.value)}
                sx={darkTextFieldSx}
              />
              <TextField
                label="Contact Pref."
                variant="outlined"
                size="small"
                placeholder="sms, email, etc."
                value={selectedContact.contact_preference || ''}
                onChange={(e) => handleEditChange('contact_preference', e.target.value)}
                sx={darkTextFieldSx}
              />
              <TextField
                label="Company"
                variant="outlined"
                size="small"
                value={selectedContact.company || ''}
                onChange={(e) => handleEditChange('company', e.target.value)}
                sx={darkTextFieldSx}
              />
              <TextField
                label="Role"
                variant="outlined"
                size="small"
                value={selectedContact.role || ''}
                onChange={(e) => handleEditChange('role', e.target.value)}
                sx={darkTextFieldSx}
              />
              <TextField
                label="Context"
                variant="outlined"
                size="small"
                placeholder="Important notes..."
                value={selectedContact.context || ''}
                onChange={(e) => handleEditChange('context', e.target.value)}
                sx={darkTextFieldSx}
              />

              {/* Agents multi-select */}
              <FormControl variant="outlined" size="small" sx={darkSelectSx}>
                <InputLabel>Agents Permitted to Contact</InputLabel>
                <Select
                  multiple
                  label="Agents Permitted to Contact"
                  value={selectedContact.agent_ids || []}
                  onChange={(e) => handleEditChange('agent_ids', e.target.value)}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em style={{ color: '#777' }}>No agents selected</em>;
                    }
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {selected.map((id) => {
                          const a = agents.find((agent) => agent.assistant_id === id);
                          return (
                            <Chip
                              key={id}
                              variant="outlined"
                              label={a ? a.assistant_name : id}
                              size="small"
                              sx={{
                                backgroundColor: '#424242',
                                color: '#fff',
                                borderColor: '#fff',
                              }}
                            />
                          );
                        })}
                      </Box>
                    );
                  }}
                >
                  {agents.length === 0 ? (
                    <MenuItem disabled>
                      <em>No agents found</em>
                    </MenuItem>
                  ) : (
                    agents.map((agent) => (
                      <MenuItem key={agent.assistant_id} value={agent.assistant_id}>
                        {agent.assistant_name || agent.assistant_id}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={closeEditDialog} color="inherit">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSaveContact} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* SNACKBAR */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactsPage;
