// src/components/RAGAgentDocsPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, Card, CardHeader, CardContent, Button, List, ListItem, ListItemIcon, ListItemText, Checkbox } from '@mui/material';
import useAuthToken from '../hooks/useAuthToken';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const RAGAgentDocsPage = ({ assistantId, onBack }) => {
  const token = useAuthToken();
  const [documents, setDocuments] = useState([]);
  const [selectedDocs, setSelectedDocs] = useState([]);

  const fetchAgentDocs = async () => {
    if (!token || !assistantId) return;
    try {
      const response = await axios.get(`${API_BASE_URL}/rag_documents/agent_docs/${assistantId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDocuments(response.data.documents);
      const assigned = response.data.documents.filter(doc => doc.assigned).map(doc => doc.doc_id);
      setSelectedDocs(assigned);
    } catch (error) {
      console.error('Error fetching agent docs:', error);
    }
  };

  useEffect(() => {
    fetchAgentDocs();
  }, [token, assistantId]);

  const handleToggle = (doc_id) => {
    const currentIndex = selectedDocs.indexOf(doc_id);
    const newSelected = [...selectedDocs];
    if (currentIndex === -1) {
      newSelected.push(doc_id);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    setSelectedDocs(newSelected);
  };

  const handleSave = async () => {
    if (!token || !assistantId) return;
    try {
      await axios.post(`${API_BASE_URL}/rag_documents/agent_docs/${assistantId}/update`, {
        doc_ids: selectedDocs
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert('Documents updated for agent!');
      if (onBack) onBack();
    } catch (error) {
      console.error('Error updating agent docs:', error);
      alert('Error updating documents for agent');
    }
  };

  return (
    <Box>
      <Typography variant="h4" mb={3}>Agent Document Assignments</Typography>
      <Card sx={{ mb: 3 }}>
        <CardHeader title="Assign Documents to Agent" />
        <CardContent>
          {documents.length === 0 ? (
            <Typography>No documents available.</Typography>
          ) : (
            <List>
              {documents.map((doc) => (
                <ListItem key={doc.doc_id} button onClick={() => handleToggle(doc.doc_id)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedDocs.indexOf(doc.doc_id) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText 
                    primary={doc.filename}
                    secondary={`Doc ID: ${doc.doc_id} | Chunks: ${doc.chunk_count}`}
                  />
                </ListItem>
              ))}
            </List>
          )}
          <Button variant="contained" color="primary" onClick={handleSave} sx={{ mt: 2 }}>Save</Button>
          <Button variant="text" onClick={onBack} sx={{ mt: 2, ml: 2 }}>Back</Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default RAGAgentDocsPage;
