// src/components/UserPaymentMethod.js

import React, { useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Card, CardContent, Typography, Button, CircularProgress, Box } from '@mui/material';
import axios from 'axios';
import { CheckCircle, Cancel } from '@mui/icons-material';
import useAuthToken from '../hooks/useAuthToken';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const UserPaymentMethod = ({ 
    token,
    isAdding,
    setIsAdding,
    clientSecret,
    createSetupIntent
}) => {
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchPaymentMethod = async () => {
        if (!token) return;
        try {
            const response = await axios.get(`${API_BASE_URL}/stripe/get-default-payment-method/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPaymentMethod(response.data || null);
        } catch (error) {
            console.error("Error fetching payment method:", error);
        }
    };

    const fetchSubscriptionStatus = async () => {
        if (!token) return;
        try {
            const response = await axios.get(`${API_BASE_URL}/stripe/get-subscription-status/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setSubscriptionStatus(response.data.status);
        } catch (error) {
            console.error("Error fetching subscription status:", error);
        }
    };

    useEffect(() => {
        if (token) {
            fetchPaymentMethod();
            fetchSubscriptionStatus();
        }
    }, [token]);

    const handleActivateSubscription = async () => {
        try {
            setLoading(true);
            await axios.post(`${API_BASE_URL}/stripe/create-subscription/`, {}, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setSubscriptionStatus('active');
            setLoading(false);
        } catch (error) {
            console.error("Error activating subscription:", error);
            setLoading(false);
        }
    };

    const handleCancelSubscription = async () => {
        try {
            setLoading(true);
            await axios.post(`${API_BASE_URL}/stripe/cancel-subscription/`, {}, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setSubscriptionStatus('inactive');
            setLoading(false);
        } catch (error) {
            console.error("Error canceling subscription:", error);
            setLoading(false);
        }
    };

    const handleRemovePaymentMethod = async () => {
        try {
            setLoading(true);
            await axios.post(`${API_BASE_URL}/stripe/remove-payment-method/`, {}, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setPaymentMethod(null);
            setLoading(false);
        } catch (error) {
            console.error("Error removing payment method:", error);
            setLoading(false);
        }
    };

    return (
        <Card sx={{ backgroundColor: '#1e1e1e', boxShadow: 3, mb: 4 }}>
            <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h5" sx={{ color: 'white' }}>
                        Payment Method
                    </Typography>
                    <Button variant="contained" color="primary" onClick={createSetupIntent} disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : paymentMethod ? "Update Payment Method" : "Add Payment Method"}
                    </Button>
                </Box>

                {isAdding && clientSecret && (
                    <PaymentForm
                        clientSecret={clientSecret}
                        token={token}
                        setIsAdding={setIsAdding}
                        setPaymentMethod={setPaymentMethod}
                    />
                )}

                {!isAdding && paymentMethod && (
                    <Box mt={2}>
                        <Typography variant="subtitle2" color="textSecondary">
                            Card Type
                        </Typography>
                        <Typography variant="body1" color="primary.main">
                            {paymentMethod.brand ? paymentMethod.brand.charAt(0).toUpperCase() + paymentMethod.brand.slice(1) : "Unknown"} ending in {paymentMethod.last4 || "****"}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary" mt={2}>
                            Expiration Date
                        </Typography>
                        <Typography variant="body1" color="primary.main">
                            {paymentMethod.exp_month && paymentMethod.exp_year ? `${paymentMethod.exp_month}/${paymentMethod.exp_year}` : "N/A"}
                        </Typography>
                        {paymentMethod && (
                            <Button variant="contained" color="secondary" onClick={handleRemovePaymentMethod} disabled={loading} sx={{ mt: 2 }}>
                                {loading ? <CircularProgress size={24} /> : "Remove Payment Method"}
                            </Button>
                        )}
                    </Box>
                )}

                {!isAdding && (
                    <Box mt={4} display="flex" alignItems="center">
                        <Typography variant="h6" color="white" sx={{ mr: 1 }}>
                            Subscription Status:
                        </Typography>
                        {subscriptionStatus === 'active' ? (
                            <>
                                <CheckCircle color="success" sx={{ mr: 1 }} />
                                <Typography variant="h6" color="success.main">Active</Typography>
                            </>
                        ) : (
                            <>
                                <Cancel color="error" sx={{ mr: 1 }} />
                                <Typography variant="h6" color="error.main">Inactive</Typography>
                            </>
                        )}
                        {subscriptionStatus === 'active' ? (
                            <Button variant="contained" color="secondary" onClick={handleCancelSubscription} disabled={loading} sx={{ ml: 2 }}>
                                {loading ? <CircularProgress size={24} /> : "Cancel Subscription"}
                            </Button>
                        ) : (
                            paymentMethod && (
                                <Button variant="contained" color="primary" onClick={handleActivateSubscription} disabled={loading} sx={{ ml: 2 }}>
                                    {loading ? <CircularProgress size={24} /> : "Activate Subscription"}
                                </Button>
                            )
                        )}
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

const PaymentForm = ({ clientSecret, token, setIsAdding, setPaymentMethod }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);

    const handleSavePaymentMethod = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) return;

        setLoading(true);

        try {
            const { setupIntent, error } = await stripe.confirmSetup({
                elements,
                redirect: 'if_required',
            });

            if (error) {
                console.error("Error confirming setup:", error.message);
                setLoading(false);
                return;
            }

            const paymentMethodId = setupIntent.payment_method;

            await axios.post(
                `${API_BASE_URL}/stripe/confirm-setup-intent/`,
                { payment_method_id: paymentMethodId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const updatedPaymentMethod = await axios.get(`${API_BASE_URL}/stripe/get-default-payment-method/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPaymentMethod(updatedPaymentMethod.data || null);
            setIsAdding(false);
            setLoading(false);
        } catch (error) {
            console.error("Error during payment method setup:", error);
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSavePaymentMethod}>
            <PaymentElement />
            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }} disabled={loading}>
                {loading ? <CircularProgress size={24} /> : "Save Payment Method"}
            </Button>
        </form>
    );
};

export default UserPaymentMethod;




// // src/components/UserPaymentMethod.js

// import React, { useState, useEffect } from 'react';
// import { useStripe, useElements, PaymentElement, Elements } from '@stripe/react-stripe-js';
// import { Card, CardContent, Typography, Button, CircularProgress, Box } from '@mui/material';
// import axios from 'axios';
// import { CheckCircle, Cancel } from '@mui/icons-material'; // Add these icons for subscription status
// import useAuthToken from '../hooks/useAuthToken';
// import { loadStripe } from '@stripe/stripe-js';

// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

// const UserPaymentMethod = () => {
//     const [clientSecret, setClientSecret] = useState(null);
//     const [paymentMethod, setPaymentMethod] = useState(null);
//     const [subscriptionStatus, setSubscriptionStatus] = useState(null);
//     const [isAdding, setIsAdding] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const token = useAuthToken();

//     const createSetupIntent = async () => {
//         if (!token) return;

//         try {
//             setLoading(true);
//             const response = await axios.post(
//                 `${API_BASE_URL}/stripe/create-setup-intent/`,
//                 {},
//                 {
//                     headers: {
//                         Authorization: `Bearer ${token}`,
//                     },
//                 }
//             );
//             setClientSecret(response.data.client_secret);
//             setLoading(false);
//             setIsAdding(true);
//         } catch (error) {
//             console.error("Error creating SetupIntent:", error);
//             setLoading(false);
//         }
//     };

//     const fetchPaymentMethod = async () => {
//         if (!token) return;
//         try {
//             const response = await axios.get(`${API_BASE_URL}/stripe/get-default-payment-method/`, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             });
//             setPaymentMethod(response.data || null);
//         } catch (error) {
//             console.error("Error fetching payment method:", error);
//         }
//     };

//     const fetchSubscriptionStatus = async () => {
//         if (!token) return;
//         try {
//             const response = await axios.get(`${API_BASE_URL}/stripe/get-subscription-status/`, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             });
//             setSubscriptionStatus(response.data.status);
//         } catch (error) {
//             console.error("Error fetching subscription status:", error);
//         }
//     };

//     useEffect(() => {
//         if (token) {
//             fetchPaymentMethod();
//             fetchSubscriptionStatus();
//         }
//     }, [token]);

//     const handleActivateSubscription = async () => {
//         try {
//             setLoading(true);
//             const response = await axios.post(`${API_BASE_URL}/stripe/create-subscription/`, {}, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             });
//             setSubscriptionStatus('active');
//             setLoading(false);
//         } catch (error) {
//             console.error("Error activating subscription:", error);
//             setLoading(false);
//         }
//     };

//     const handleCancelSubscription = async () => {
//         try {
//             setLoading(true);
//             const response = await axios.post(`${API_BASE_URL}/stripe/cancel-subscription/`, {}, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             });
//             setSubscriptionStatus('inactive');
//             setLoading(false);
//         } catch (error) {
//             console.error("Error canceling subscription:", error);
//             setLoading(false);
//         }
//     };

//     const handleRemovePaymentMethod = async () => {
//         try {
//             setLoading(true);
//             await axios.post(`${API_BASE_URL}/stripe/remove-payment-method/`, {}, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             });
//             setPaymentMethod(null);
//             setLoading(false);
//         } catch (error) {
//             console.error("Error removing payment method:", error);
//             setLoading(false);
//         }
//     };

//     return (
//         <Card sx={{ backgroundColor: '#1e1e1e', boxShadow: 3, mb: 4 }}>
//             <CardContent>
//                 <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
//                     <Typography variant="h5" sx={{ color: 'white' }}>
//                         Payment Method
//                     </Typography>
//                     <Button variant="contained" color="primary" onClick={createSetupIntent} disabled={loading}>
//                         {loading ? <CircularProgress size={24} /> : paymentMethod ? "Update Payment Method" : "Add Payment Method"}
//                     </Button>
//                 </Box>

//                 {isAdding && clientSecret && (
//                     <Elements stripe={stripePromise} options={{ clientSecret }}>
//                         <PaymentForm clientSecret={clientSecret} token={token} setIsAdding={setIsAdding} setPaymentMethod={setPaymentMethod} />
//                     </Elements>
//                 )}

//                 {!isAdding && paymentMethod && (
//                     <Box mt={2}>
//                         <Typography variant="subtitle2" color="textSecondary">
//                             Card Type
//                         </Typography>
//                         <Typography variant="body1" color="primary.main">
//                             {paymentMethod.brand ? paymentMethod.brand.charAt(0).toUpperCase() + paymentMethod.brand.slice(1) : "Unknown"} ending in {paymentMethod.last4 || "****"}
//                         </Typography>
//                         <Typography variant="subtitle2" color="textSecondary" mt={2}>
//                             Expiration Date
//                         </Typography>
//                         <Typography variant="body1" color="primary.main">
//                             {paymentMethod.exp_month && paymentMethod.exp_year ? `${paymentMethod.exp_month}/${paymentMethod.exp_year}` : "N/A"}
//                         </Typography>
//                         {paymentMethod && (
//                             <Button variant="contained" color="secondary" onClick={handleRemovePaymentMethod} disabled={loading} sx={{ mt: 2 }}>
//                                 {loading ? <CircularProgress size={24} /> : "Remove Payment Method"}
//                             </Button>
//                         )}
//                     </Box>
//                 )}

//                 {!isAdding && (
//                     <Box mt={4} display="flex" alignItems="center">
//                         <Typography variant="h6" color="white" sx={{ mr: 1 }}>
//                             Subscription Status:
//                         </Typography>
//                         {subscriptionStatus === 'active' ? (
//                             <>
//                                 <CheckCircle color="success" sx={{ mr: 1 }} />
//                                 <Typography variant="h6" color="success.main">Active</Typography>
//                             </>
//                         ) : (
//                             <>
//                                 <Cancel color="error" sx={{ mr: 1 }} />
//                                 <Typography variant="h6" color="error.main">Inactive</Typography>
//                             </>
//                         )}
//                         {subscriptionStatus === 'active' ? (
//                             <Button variant="contained" color="secondary" onClick={handleCancelSubscription} disabled={loading} sx={{ ml: 2 }}>
//                                 {loading ? <CircularProgress size={24} /> : "Cancel Subscription"}
//                             </Button>
//                         ) : (
//                             paymentMethod && (
//                                 <Button variant="contained" color="primary" onClick={handleActivateSubscription} disabled={loading} sx={{ ml: 2 }}>
//                                     {loading ? <CircularProgress size={24} /> : "Activate Subscription"}
//                                 </Button>
//                             )
//                         )}
//                     </Box>
//                 )}
//             </CardContent>
//         </Card>
//     );
// };

// const PaymentForm = ({ clientSecret, token, setIsAdding, setPaymentMethod }) => {
//     const stripe = useStripe();
//     const elements = useElements();
//     const [loading, setLoading] = useState(false);

//     const handleSavePaymentMethod = async (event) => {
//         event.preventDefault();
//         if (!stripe || !elements) return;

//         setLoading(true);

//         try {
//             const { setupIntent, error } = await stripe.confirmSetup({
//                 elements,
//                 redirect: 'if_required',
//             });

//             if (error) {
//                 console.error("Error confirming setup:", error.message);
//                 setLoading(false);
//                 return;
//             }

//             const paymentMethodId = setupIntent.payment_method;

//             await axios.post(
//                 `${API_BASE_URL}/stripe/confirm-setup-intent/`,
//                 { payment_method_id: paymentMethodId },
//                 {
//                     headers: {
//                         Authorization: `Bearer ${token}`,
//                     },
//                 }
//             );

//             const updatedPaymentMethod = await axios.get(`${API_BASE_URL}/stripe/get-default-payment-method/`, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             });
//             setPaymentMethod(updatedPaymentMethod.data || null);
//             setIsAdding(false);
//             setLoading(false);
//         } catch (error) {
//             console.error("Error during payment method setup:", error);
//             setLoading(false);
//         }
//     };

//     return (
//         <form onSubmit={handleSavePaymentMethod}>
//             <PaymentElement />
//             <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }} disabled={loading}>
//                 {loading ? <CircularProgress size={24} /> : "Save Payment Method"}
//             </Button>
//         </form>
//     );
// };

// export default UserPaymentMethod;
