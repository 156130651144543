// src/components/workflows/SavedWorkflowsList.js
import React from 'react';
import { Card, CardHeader, CardContent, Avatar, Typography } from '@mui/material';
import EditWorkflowCard from './EditWorkflowCard';

/**
 * SavedWorkflowsList:
 * - Displays a card header ("View / Edit Saved Workflows")
 * - Loops over `workflows`
 * - For each workflow, renders `EditWorkflowCard`
 * - Passes down `tools`, `agents`, `onSave`, and `onDelete` so that
 *   each EditWorkflowCard can function properly.
 */
const SavedWorkflowsList = ({ workflows, tools, agents, onSave, onDelete }) => {
  return (
    <Card sx={{ backgroundColor: '#1e1e1e', boxShadow: 3 }}>
      <CardHeader
        avatar={<Avatar sx={{ bgcolor: 'secondary.main' }}>V</Avatar>}
        title={<Typography variant="h5">View / Edit Saved Workflows</Typography>}
        subheader={
          <Typography variant="body2" color="text.secondary">
            Below is a list of previously created workflows. Click to expand and edit.
          </Typography>
        }
      />
      <CardContent>
        {workflows.length === 0 && (
          <Typography>No workflows saved yet.</Typography>
        )}
        {workflows.map((wf) => (
          <EditWorkflowCard
            key={wf.id}
            workflow={wf}
            tools={tools}
            agents={agents}
            onSave={onSave}
            onDelete={onDelete}  // optional if you want to show delete functionality
          />
        ))}
      </CardContent>
    </Card>
  );
};

export default SavedWorkflowsList;

