// src/components/AccountPage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Box, CircularProgress, Card, CardHeader, Avatar, CardContent, Button, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useAuthToken from '../hooks/useAuthToken';
import UtilizationData from './UtilizationData';
import UserInfoCard from './UserInfoCard';
import UserPaymentMethod from './UserPaymentMethod';
import UserTransactionHistory from './UserTransactionHistory';
import DeviceOrderForm from './DeviceOrderForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AccountPage = () => {
  const token = useAuthToken();
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    address_line1: '',
    address_line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  });
  const [apiKey, setApiKey] = useState('');
  const [showApiKey, setShowApiKey] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOrderFormOpen, setIsOrderFormOpen] = useState(false);

  // New states for managing payment setup
  const [clientSecret, setClientSecret] = useState(null);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    const fetchAccountData = async () => {
      if (!token) {
        setLoading(false);
        return;
      }

      try {
        const userResponse = await axios.get(`${API_BASE_URL}/users/user-details/`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });

        if (userResponse.data.user) {
          setUserData(userResponse.data.user);
        } else {
          console.log('User profile not found.');
        }

        const apiKeyResponse = await axios.get(`${API_BASE_URL}/auth/get-api-key/`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });

        if (apiKeyResponse.data && apiKeyResponse.data.api_key) {
          setApiKey(apiKeyResponse.data.api_key);
        } else {
          console.log('API key not found.');
        }

        setLoading(false);
      } catch (err) {
        console.error('Error fetching account data:', err);
        setError('Unable to load account data.');
        setLoading(false);
      }
    };

    fetchAccountData();
  }, [token]);

  const handleUserDataUpdate = (updatedData) => {
    setUserData(updatedData);
  };

  const toggleApiKeyVisibility = () => {
    setShowApiKey(!showApiKey);
  };

  // Create SetupIntent to initiate payment method addition or update
  const createSetupIntent = async () => {
    if (!token) return;
    try {
      const response = await axios.post(
        `${API_BASE_URL}/stripe/create-setup-intent/`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setClientSecret(response.data.client_secret);
      setIsAdding(true);
    } catch (error) {
      console.error("Error creating SetupIntent:", error);
    }
  };

  // When we stop adding/updating a payment method, reset the clientSecret
  useEffect(() => {
    if (!isAdding) {
      setClientSecret(null);
    }
  }, [isAdding]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box p={3} bgcolor="#121212" minHeight="100vh">
      {/* Header Card */}
      <Card sx={{ backgroundColor: '#1e1e1e', boxShadow: 3, mb: 3 }}>
        <CardHeader
          avatar={<Avatar sx={{ bgcolor: 'primary.main' }}>A</Avatar>}
          title={
            <Typography variant="h5" component="div">
              Account Details
            </Typography>
          }
          subheader={<Typography variant="body2" color="text.secondary">Manage your account details</Typography>}
        />
      </Card>

      {/* Utilization Data Card */}
      <UtilizationData />

      {/* User Information Card */}
      <UserInfoCard initialUserData={userData} onUserDataUpdate={handleUserDataUpdate} />

      {/* User Payment Method Card */}
      {isAdding ? (
        clientSecret ? (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <UserPaymentMethod
              token={token}
              isAdding={isAdding}
              setIsAdding={setIsAdding}
              clientSecret={clientSecret}
              createSetupIntent={createSetupIntent}
            />
          </Elements>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center"><CircularProgress /></Box>
        )
      ) : (
        <UserPaymentMethod
          token={token}
          isAdding={isAdding}
          setIsAdding={setIsAdding}
          clientSecret={clientSecret}
          createSetupIntent={createSetupIntent}
        />
      )}

      {/* Centered Device Order Button */}
      <Box display="flex" justifyContent="center" mt={4} mb={2}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => setIsOrderFormOpen(!isOrderFormOpen)}
          sx={{ width: '80%', maxWidth: 300, fontSize: '1.2rem' }}
        >
          Order EchoBach Device(s)
        </Button>
      </Box>

      {/* Device Order Form */}
      {isOrderFormOpen && (
        <DeviceOrderForm userData={userData} apiKey={apiKey} />
      )}

      {/* Transaction History */}
      <UserTransactionHistory />

      {/* API Key Card */}
      <Card sx={{ backgroundColor: '#1e1e1e', boxShadow: 3, mt: 3 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            API Key
          </Typography>
          <Typography variant="body1" style={{ display: 'flex', alignItems: 'center' }}>
            {showApiKey ? apiKey : '********'}
            <IconButton onClick={toggleApiKeyVisibility}>
              {showApiKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AccountPage;

// // src/components/AccountPage.js

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Typography, Box, CircularProgress, Card, CardHeader, Avatar, CardContent, Button, IconButton } from '@mui/material';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import useAuthToken from '../hooks/useAuthToken';
// import UtilizationData from './UtilizationData';
// import UserInfoCard from './UserInfoCard';
// import UserPaymentMethod from './UserPaymentMethod';
// import UserTransactionHistory from './UserTransactionHistory';
// import DeviceOrderForm from './DeviceOrderForm';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// const AccountPage = () => {
//   const token = useAuthToken();
//   const [userData, setUserData] = useState({
//     first_name: '',
//     last_name: '',
//     email: '',
//     phone_number: '',
//     address_line1: '',
//     address_line2: '',
//     city: '',
//     state: '',
//     postal_code: '',
//     country: '',
//   });
//   const [apiKey, setApiKey] = useState('');
//   const [showApiKey, setShowApiKey] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [isOrderFormOpen, setIsOrderFormOpen] = useState(false); // State for toggling order form

//   useEffect(() => {
//     const fetchAccountData = async () => {
//       if (!token) {
//         setLoading(false);
//         return;
//       }

//       try {
//         const userResponse = await axios.get(`${API_BASE_URL}/users/user-details/`, {
//           headers: { 'Authorization': `Bearer ${token}` },
//         });

//         if (userResponse.data.user) {
//           setUserData(userResponse.data.user);
//         } else {
//           console.log('User profile not found.');
//         }

//         const apiKeyResponse = await axios.get(`${API_BASE_URL}/auth/get-api-key/`, {
//           headers: { 'Authorization': `Bearer ${token}` },
//         });

//         if (apiKeyResponse.data && apiKeyResponse.data.api_key) {
//           setApiKey(apiKeyResponse.data.api_key);
//         } else {
//           console.log('API key not found.');
//         }

//         setLoading(false);
//       } catch (err) {
//         console.error('Error fetching account data:', err);
//         setError('Unable to load account data.');
//         setLoading(false);
//       }
//     };

//     fetchAccountData();
//   }, [token]);

//   const handleUserDataUpdate = (updatedData) => {
//     setUserData(updatedData);
//   };

//   const toggleApiKeyVisibility = () => {
//     setShowApiKey(!showApiKey);
//   };

//   if (loading) {
//     return (
//       <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
//         <CircularProgress />
//       </Box>
//     );
//   }

//   if (error) {
//     return (
//       <Box p={3}>
//         <Typography variant="h6" color="error">
//           {error}
//         </Typography>
//       </Box>
//     );
//   }

//   return (
//     <Box p={3} bgcolor="#121212" minHeight="100vh">
//       {/* Header Card */}
//       <Card sx={{ backgroundColor: '#1e1e1e', boxShadow: 3, mb: 3 }}>
//         <CardHeader
//           avatar={<Avatar sx={{ bgcolor: 'primary.main' }}>A</Avatar>}
//           title={
//             <Typography variant="h5" component="div">
//               Account Details
//             </Typography>
//           }
//           subheader={<Typography variant="body2" color="text.secondary">Manage your account details</Typography>}
//         />
//       </Card>

//       {/* Utilization Data Card */}
//       <UtilizationData />

//       {/* User Information Card */}
//       <UserInfoCard initialUserData={userData} onUserDataUpdate={handleUserDataUpdate} />

//       {/* User Payment Method Card */}
//       <Elements stripe={stripePromise}>
//         <UserPaymentMethod />
//       </Elements>

//       {/* Centered Device Order Button */}
//       <Box display="flex" justifyContent="center" mt={4} mb={2}>
//         <Button
//           variant="contained"
//           color="primary"
//           size="large"
//           onClick={() => setIsOrderFormOpen(!isOrderFormOpen)}
//           sx={{ width: '80%', maxWidth: 300, fontSize: '1.2rem' }}
//         >
//           Order EchoBach Device(s)
//         </Button>
//       </Box>

//       {/* Device Order Form */}
//       {isOrderFormOpen && (
//         <DeviceOrderForm userData={userData} apiKey={apiKey} />

//       )}

//       {/* Transaction History */}
//       <UserTransactionHistory />

//       {/* API Key Card */}
//       <Card sx={{ backgroundColor: '#1e1e1e', boxShadow: 3, mt: 3 }}>
//         <CardContent>
//           <Typography variant="h5" gutterBottom>
//             API Key
//           </Typography>
//           <Typography variant="body1" style={{ display: 'flex', alignItems: 'center' }}>
//             {showApiKey ? apiKey : '********'}
//             <IconButton onClick={toggleApiKeyVisibility}>
//               {showApiKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
//             </IconButton>
//           </Typography>
//         </CardContent>
//       </Card>
//     </Box>
//   );
// };

// export default AccountPage;
