// src/components/RAGDocumentsPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, Card, CardHeader, CardContent, Button, List, ListItem, ListItemText, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import useAuthToken from '../hooks/useAuthToken';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const RAGDocumentsPage = () => {
  const token = useAuthToken();
  const [selectedFile, setSelectedFile] = useState(null);
  const [documents, setDocuments] = useState([]);

  const fetchDocuments = async () => {
    if (!token) return;

    try {
      const response = await axios.get(`${API_BASE_URL}/rag_documents/list`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDocuments(response.data.documents);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchDocuments();
    }
  }, [token]);

  const handleFileUpload = async () => {
    if (!selectedFile || !token) return;

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      await axios.post(`${API_BASE_URL}/rag_documents/upload`, formData, {
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('File uploaded successfully');
      setSelectedFile(null);
      fetchDocuments();
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file');
    }
  };

  const handleDeleteDocument = async (doc_id) => {
    if (!window.confirm('Are you sure you want to delete this document?') || !token) return;

    try {
      await axios.delete(`${API_BASE_URL}/rag_documents/${doc_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchDocuments();
    } catch (error) {
      console.error('Error deleting document:', error);
      alert('Error deleting document');
    }
  };

  return (
    <Box>
      <Typography variant="h4" mb={3}>RAG Documents</Typography>
      
      <Card sx={{ mb: 3 }}>
        <CardHeader title="Upload Document" />
        <CardContent>
          <Typography variant="body2" mb={2}>
            Upload a .txt or .docx file. The text will be parsed, embedded, and made available for RAG search.
          </Typography>
          <input
            type="file"
            accept=".txt,.docx"
            onChange={(e) => setSelectedFile(e.target.files[0])}
            style={{ marginBottom: '1rem' }}
          />
          <br/>
          <Button variant="contained" onClick={handleFileUpload}>Upload</Button>
        </CardContent>
      </Card>

      <Card>
        <CardHeader title="Uploaded Documents" />
        <CardContent>
          {documents.length === 0 ? (
            <Typography>No documents uploaded yet.</Typography>
          ) : (
            <List>
              {documents.map((doc) => (
                <ListItem
                  key={doc.doc_id}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteDocument(doc.doc_id)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        {doc.filename}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" color="text.secondary">
                        Doc ID: {doc.doc_id} | Chunks: {doc.chunk_count}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default RAGDocumentsPage;
