// src/components/PhoneNumbersPage.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, List, ListItem, ListItemText, Card, CardContent, CardHeader, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import axios from 'axios';
import useAuthToken from '../hooks/useAuthToken';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const PhoneNumbersPage = ({ refreshBachs }) => {
  const token = useAuthToken();
  const [subaccount, setSubaccount] = useState(null);
  const [subaccountLoading, setSubaccountLoading] = useState(false);
  const [areaCode, setAreaCode] = useState('');
  const [contains, setContains] = useState('');
  const [availableNumbers, setAvailableNumbers] = useState([]);
  const [loading, setLoading] = useState(false);

  // Dialog state for confirmation
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(null);
  
  // Fetch or create Twilio subaccount
  const fetchOrCreateSubaccount = async () => {
    setSubaccountLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/twilio/twilio-subaccount`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSubaccount(response.data);
    } catch (error) {
      console.error('Error fetching/creating subaccount:', error);
    } finally {
      setSubaccountLoading(false);
    }
  };

  const searchNumbers = async () => {
    setLoading(true);
    try {
      const params = {};
      if (areaCode) params.area_code = areaCode;
      if (contains) params.contains = contains;

      const response = await axios.get(`${API_BASE_URL}/twilio/search-numbers`, {
        headers: { Authorization: `Bearer ${token}` },
        params
      });
      setAvailableNumbers(response.data.available_numbers);
    } catch (error) {
      console.error('Error searching numbers:', error);
    } finally {
      setLoading(false);
    }
  };

  const openConfirmDialog = (number) => {
    setSelectedNumber(number);
    setConfirmOpen(true);
  };

  const closeConfirmDialog = () => {
    setConfirmOpen(false);
    setSelectedNumber(null);
  };

  const purchaseNumber = async () => {
    if (!subaccount) {
      alert('Please wait until a subaccount is created.');
      return;
    }
    if (!selectedNumber) {
      alert('No number selected.');
      return;
    }
    try {
      await axios.post(`${API_BASE_URL}/twilio/purchase-number`, 
        { selected_number: selectedNumber },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert('Number purchased successfully!');
      if (refreshBachs) refreshBachs();
      closeConfirmDialog();
    } catch (error) {
      console.error('Error purchasing number:', error);
      alert('Error purchasing number');
    }
  };

  useEffect(() => {
    if (token) {
      fetchOrCreateSubaccount();
    }
  }, [token]);

  return (
    <Box sx={{ color: 'primary.main' }}>
      <Card sx={{ mb: 3, backgroundColor: '#1e1e1e', boxShadow: 3 }}>
        <CardHeader 
            title={<Typography variant="h5">Add Phone Number</Typography>}
            subheader={<Typography variant="body2">Provision a new phone number bach.</Typography>}
        />
        {/* <CardContent>
          {subaccountLoading ? (
            <Typography>Creating/retrieving Twilio subaccount...</Typography>
          ) : subaccount ? (
            <Typography>Twilio Subaccount: {subaccount.twilio_sub_account_sid}</Typography>
          ) : (
            <Typography>Twilio subaccount not yet available.</Typography>
          )}
        </CardContent> */}
      </Card>

      <Card sx={{ mb: 3, backgroundColor: '#1e1e1e', boxShadow: 3 }}>
        <CardHeader title="Search for Available Numbers" />
        <CardContent>
          <TextField
            label="Area Code"
            variant="outlined"
            value={areaCode}
            onChange={(e) => setAreaCode(e.target.value)}
            sx={{ mr: 2, mb: 2 }}
          />
          <TextField
            label="Contains"
            variant="outlined"
            value={contains}
            onChange={(e) => setContains(e.target.value)}
            sx={{ mr: 2, mb: 2 }}
          />
          <Button variant="contained" color="primary" onClick={searchNumbers} disabled={loading || !subaccount}>
            {loading ? 'Searching...' : 'Search'}
          </Button>

          <List>
            {availableNumbers.map((num, idx) => (
              <ListItem key={idx}>
                <ListItemText primary={`${num.friendly_name || num.phone_number} - ${num.phone_number}`} />
                <Button variant="contained" color="secondary" onClick={() => openConfirmDialog(num.phone_number)}>
                  Buy Phone Number
                </Button>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>

      {/* Confirmation Dialog */}
      <Dialog open={confirmOpen} onClose={closeConfirmDialog}>
        <DialogTitle>Confirm Purchase</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You're about to purchase {selectedNumber}. By buying this number, you agree to start a $14/month subscription for this number.
            Would you like to confirm?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDialog} color="primary">Cancel</Button>
          <Button onClick={purchaseNumber} color="secondary" variant="contained">Confirm</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PhoneNumbersPage;
