// src/components/PhoneBachThreads.js
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Box,
  IconButton,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import useAuthToken from '../hooks/useAuthToken';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const PhoneBachThreads = ({ deviceId, assistantId }) => {
  const [threads, setThreads] = useState([]);
  const [expandedThreadIds, setExpandedThreadIds] = useState([]);
  const [threadDetails, setThreadDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [callHistoryMap, setCallHistoryMap] = useState({});
  const token = useAuthToken();

  useEffect(() => {
    const fetchThreads = async () => {
      setLoading(true);
      try {
        const params = {
          limit: 10,
          ...(deviceId && { device_id: deviceId }),
          ...(assistantId && { assistant_id: assistantId }),
        };

        const response = await axios.get(`${API_BASE_URL}/bach_threads/threads/`, {
          headers: { Authorization: `Bearer ${token}` },
          params,
        });
        setThreads(response.data);
      } catch (error) {
        console.error('Error fetching threads:', error);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchThreads();
    }
  }, [token, deviceId, assistantId]);

  // After we have threads, fetch call history (if deviceId is known) and map thread_id to calls
  useEffect(() => {
    const fetchCallHistory = async () => {
      if (!token || !deviceId) return;
      try {
        const response = await axios.get(`${API_BASE_URL}/twilio/get_phone_calls/${deviceId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const calls = response.data.calls || [];
        // Create a map from thread_id -> call record
        const mapping = {};
        for (const call of calls) {
          if (call.thread_id) {
            mapping[call.thread_id] = call;
          }
        }
        setCallHistoryMap(mapping);
      } catch (error) {
        console.error('Error fetching phone call history:', error);
      }
    };
    if (threads.length > 0) {
      fetchCallHistory();
    }
  }, [threads, token, deviceId]);

  const handleExpandClick = (threadId) => {
    setExpandedThreadIds((prev) =>
      prev.includes(threadId)
        ? prev.filter((id) => id !== threadId)
        : [...prev, threadId]
    );
  };

  const handleDeleteThread = async (threadId) => {
    try {
      await axios.delete(`${API_BASE_URL}/bach_threads/threads/`, {
        headers: { Authorization: `Bearer ${token}` },
        data: { thread_ids: [threadId] },
      });
      setThreads((prev) => prev.filter((thread) => thread.thread_id !== threadId));
      alert('Thread deleted successfully');
    } catch (error) {
      console.error('Error deleting thread:', error);
      alert('Error deleting thread');
    }
  };

  const handleDeleteAll = async () => {
    const threadIds = threads.map((thread) => thread.thread_id);
    try {
      await axios.delete(`${API_BASE_URL}/bach_threads/threads/`, {
        headers: { Authorization: `Bearer ${token}` },
        data: { thread_ids: threadIds },
      });
      alert('All threads deleted successfully');
      setThreads([]);
    } catch (error) {
      console.error('Error deleting all threads:', error);
      alert('Error deleting all threads');
    }
  };

  const fetchThreadDetails = async (threadId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/bach_threads/threads/${threadId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setThreadDetails((prev) => ({ ...prev, [threadId]: response.data }));
    } catch (error) {
      console.error('Error fetching thread details:', error);
    }
  };

  useEffect(() => {
    expandedThreadIds.forEach((threadId) => {
      if (!threadDetails[threadId]) {
        fetchThreadDetails(threadId);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedThreadIds]);

  const formatLocalDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    if (isNaN(date)) return 'N/A';
    return date.toLocaleString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    });
  };

  return (
    <Card sx={{ mb: 3, backgroundColor: '#1e1e1e', boxShadow: 3 }}>
      <CardHeader
        title={<Typography variant="h6">Phone Interaction History</Typography>}
        subheader={
          <Typography variant="body2" color="text.secondary">
            View and manage call transcripts and metadata
          </Typography>
        }
        action={
          threads.length > 0 && (
            <Button variant="contained" color="secondary" onClick={handleDeleteAll}>
              Delete All
            </Button>
          )
        }
      />
      <CardContent>
        {loading ? (
          <Typography variant="body2">Loading threads...</Typography>
        ) : threads.length === 0 ? (
          <Typography variant="body2">No threads available.</Typography>
        ) : (
          threads.map((thread) => {
            const callRecord = callHistoryMap[thread.thread_id];
            return (
              <Card
                key={thread.thread_id}
                sx={{ mb: 2, backgroundColor: '#2a2a2a', boxShadow: 2 }}
              >
                <CardHeader
                  title={
                    <Typography variant="subtitle1">
                      Conversation from {formatLocalDate(thread.start_time)}
                    </Typography>
                  }
                  subheader={
                    callRecord ? (
                      <Typography variant="body2" color="text.secondary">
                        {callRecord.incoming_outgoing === 'incoming'
                          ? 'Inbound Call'
                          : 'Outbound Call'} | Other Party: {callRecord.other_party_number || 'N/A'}
                        {callRecord.duration && (
                          <> | Duration: {callRecord.duration}s</>
                        )}
                        {callRecord.start_time && (
                          <> | Started: {formatLocalDate(callRecord.start_time)}</>
                        )}
                        {callRecord.end_time && (
                          <> | Ended: {formatLocalDate(callRecord.end_time)}</>
                        )}
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        No call metadata available for this thread.
                      </Typography>
                    )
                  }
                  action={
                    <>
                      <IconButton
                        onClick={() => handleDeleteThread(thread.thread_id)}
                        aria-label="delete"
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                      <IconButton
                        onClick={() => handleExpandClick(thread.thread_id)}
                        aria-label="expand"
                      >
                        <ExpandMoreIcon
                          sx={{
                            transform: expandedThreadIds.includes(thread.thread_id)
                              ? 'rotate(180deg)'
                              : 'rotate(0deg)',
                            transition: 'transform 0.3s',
                          }}
                        />
                      </IconButton>
                    </>
                  }
                />
                <Collapse
                  in={expandedThreadIds.includes(thread.thread_id)}
                  timeout="auto"
                  unmountOnExit
                >
                  <CardContent>
                    {threadDetails[thread.thread_id] ? (
                      <List>
                        {threadDetails[thread.thread_id].interactions.map(
                          (interaction, index) => (
                            <ListItem key={index} alignItems="flex-start">
                              <ListItemText
                                primary={
                                  <>
                                    <Typography variant="body1" color="primary">
                                      User:
                                    </Typography>
                                    <Typography variant="body2">
                                      {interaction.user_input}
                                    </Typography>
                                  </>
                                }
                                secondary={
                                  <>
                                    <Typography variant="body1" color="secondary">
                                      Assistant:
                                    </Typography>
                                    <Typography variant="body2">
                                      {interaction.bot_response}
                                    </Typography>
                                  </>
                                }
                              />
                            </ListItem>
                          )
                        )}
                      </List>
                    ) : (
                      <Typography variant="body2">Loading interactions...</Typography>
                    )}
                  </CardContent>
                </Collapse>
              </Card>
            );
          })
        )}
      </CardContent>
    </Card>
  );
};

export default PhoneBachThreads;
