import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Avatar,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Chip,
  Collapse
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ expand }) => ({
  transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
}));

const EditWorkflowCard = ({
  workflow,
  tools,
  agents,
  onSave,
  onDelete,
}) => {
  const [localWorkflow, setLocalWorkflow] = useState({
    ...workflow,
    isExpanded: false,
  });

  const toggleExpand = () => {
    setLocalWorkflow((prev) => ({ ...prev, isExpanded: !prev.isExpanded }));
  };

  const handleFieldChange = (field, value) => {
    setLocalWorkflow((prev) => ({ ...prev, [field]: value }));
  };

  const addStep = () => {
    setLocalWorkflow((prev) => ({
      ...prev,
      steps: [
        ...prev.steps,
        { stepName: '', stepObjective: '', selectedTools: [] },
      ],
    }));
  };

  const removeStep = (index) => {
    setLocalWorkflow((prev) => ({
      ...prev,
      steps: prev.steps.filter((_, i) => i !== index),
    }));
  };

  const handleStepChange = (index, field, val) => {
    setLocalWorkflow((prev) => {
      const newSteps = prev.steps.map((s, i) =>
        i === index ? { ...s, [field]: val } : s
      );
      return { ...prev, steps: newSteps };
    });
  };

  const handleSaveChanges = () => {
    const { isExpanded, ...finalWf } = localWorkflow;
    onSave(finalWf);
  };

  return (
    <Card sx={{ mb: 2, backgroundColor: '#2a2a2a', boxShadow: 2 }}>
      <CardHeader
        avatar={<Avatar sx={{ bgcolor: 'secondary.main' }}>E</Avatar>}
        title={<Typography variant="subtitle1">{localWorkflow.name}</Typography>}
        subheader={localWorkflow.primary_objective}
        action={
          <>
            {onDelete && (
              <IconButton color="error" onClick={() => onDelete(localWorkflow.id)}>
                <DeleteIcon />
              </IconButton>
            )}
            <ExpandMore
              expand={localWorkflow.isExpanded}
              onClick={toggleExpand}
              aria-expanded={localWorkflow.isExpanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </>
        }
      />

      <Collapse in={localWorkflow.isExpanded} timeout="auto" unmountOnExit>
        <CardContent>
          <TextField
            label="Workflow Name"
            variant="filled"
            fullWidth
            value={localWorkflow.name}
            onChange={(e) => handleFieldChange('name', e.target.value)}
            sx={{ mb: 2 }}
          />

          <TextField
            label="Primary Objective"
            variant="filled"
            fullWidth
            multiline
            rows={2}
            value={localWorkflow.primary_objective}
            onChange={(e) => handleFieldChange('primary_objective', e.target.value)}
            sx={{ mb: 2 }}
          />

          <FormControl variant="filled" fullWidth sx={{ mb: 2 }}>
            <InputLabel>Assign Agents</InputLabel>
            <Select
              multiple
              value={localWorkflow.assignedAgents || []}
              onChange={(e) => handleFieldChange('assignedAgents', e.target.value)}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((assistantId) => {
                    const agentObj = agents.find((a) => a.assistant_id === assistantId);
                    const chipLabel = agentObj ? agentObj.assistant_name : assistantId;
                    return <Chip key={assistantId} label={chipLabel} />;
                  })}
                </Box>
              )}
            >
              {agents.map((agent) => (
                <MenuItem key={agent.assistant_id} value={agent.assistant_id}>
                  {agent.assistant_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            Steps
          </Typography>
          {localWorkflow.steps.map((step, index) => (
            <Card
              key={index}
              sx={{
                mt: 1,
                backgroundColor: '#3a3a3a',
                boxShadow: 1,
                border: '1px solid #444',
              }}
            >
              <CardHeader
                /* CHANGED: Use 'Step {index}' instead of 'index + 1' */
                title={
                  <Typography variant="body1">
                    Step {index}
                    {step.stepName ? ` - ${step.stepName}` : ''}
                  </Typography>
                }
                action={
                  <IconButton color="error" onClick={() => removeStep(index)}>
                    <DeleteIcon />
                  </IconButton>
                }
              />
              <CardContent>
                <TextField
                  label="Step Name"
                  variant="filled"
                  fullWidth
                  value={step.stepName}
                  onChange={(e) =>
                    handleStepChange(index, 'stepName', e.target.value)
                  }
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Step Objective"
                  variant="filled"
                  fullWidth
                  multiline
                  rows={2}
                  value={step.stepObjective}
                  onChange={(e) =>
                    handleStepChange(index, 'stepObjective', e.target.value)
                  }
                  sx={{ mb: 2 }}
                />

                <FormControl variant="filled" fullWidth>
                  <InputLabel>Select Tools</InputLabel>
                  <Select
                    multiple
                    value={step.selectedTools}
                    onChange={(e) =>
                      handleStepChange(index, 'selectedTools', e.target.value)
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((toolName) => {
                          const toolObj = tools.find((t) => t.name === toolName);
                          const chipLabel = toolObj ? toolObj.display_name : toolName;
                          return <Chip key={toolName} label={chipLabel} />;
                        })}
                      </Box>
                    )}
                  >
                    {tools.map((tool) => (
                      <MenuItem key={tool.name} value={tool.name}>
                        {tool.display_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          ))}

          <Box sx={{ mt: 2 }}>
            <Button variant="contained" startIcon={<AddIcon />} onClick={addStep} fullWidth>
              Add Step
            </Button>
          </Box>
        </CardContent>

        <CardActions>
          <Button variant="contained" color="primary" onClick={handleSaveChanges} fullWidth>
            Save Changes
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  );
};

export default EditWorkflowCard;
