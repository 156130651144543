// src/components/workflows/OrchestrationView.js
import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../../supabaseClient';
import axios from 'axios';
import AuthContext from '../../context/AuthContext';

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Collapse,
  Avatar,
  Typography,
  IconButton,
  Button,
  Chip,
  TextField
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

// --------------------------------------------------------------------
// 1) Configure your base API endpoint
// --------------------------------------------------------------------
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api-dev.echobach.com';

// --------------------------------------------------------------------
// 2) Styled ExpandMore Icon
// --------------------------------------------------------------------
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ expand }) => ({
  transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
  marginLeft: 'auto'
}));

// --------------------------------------------------------------------
// 3) Map run.status => label/icon (handle 'running')
// --------------------------------------------------------------------
const getStatusVisual = (status, theme) => {
  switch (status) {
    case 'in_progress':
    case 'running': // If your backend status is actually "running"
      return {
        label: 'Running',
        icon: (
          <RadioButtonCheckedIcon
            sx={{ color: theme.palette.primary.main, fontSize: 16, mr: 1 }}
          />
        ),
        color: theme.palette.primary.main
      };
    case 'completed':
      return {
        label: 'Completed',
        icon: (
          <RadioButtonCheckedIcon
            sx={{
              color: theme.palette.success?.main || '#4caf50',
              fontSize: 16,
              mr: 1
            }}
          />
        ),
        color: theme.palette.success?.main || '#4caf50'
      };
    case 'failed':
      return {
        label: 'Failed',
        icon: (
          <RadioButtonCheckedIcon
            sx={{
              color: theme.palette.error?.main || '#f44336',
              fontSize: 16,
              mr: 1
            }}
          />
        ),
        color: theme.palette.error?.main || '#f44336'
      };
    case 'pending':
      return {
        label: 'Pending',
        icon: (
          <RadioButtonCheckedIcon
            sx={{ color: theme.palette.text.secondary, fontSize: 16, mr: 1 }}
          />
        ),
        color: theme.palette.text.secondary
      };
    case 'canceled':
      return {
        label: 'Canceled',
        icon: (
          <RadioButtonCheckedIcon
            sx={{
              color: theme.palette.error?.main || '#f44336',
              fontSize: 16,
              mr: 1
            }}
          />
        ),
        color: theme.palette.error?.main || '#f44336'
      };
    default:
      return {
        label: status || 'unknown',
        icon: (
          <RadioButtonUncheckedIcon
            sx={{ color: theme.palette.text.secondary, fontSize: 16, mr: 1 }}
          />
        ),
        color: theme.palette.text.secondary
      };
  }
};

// --------------------------------------------------------------------
// 4) Main OrchestrationView Component
// --------------------------------------------------------------------
const OrchestrationView = () => {
  const theme = useTheme();
  const { user } = useContext(AuthContext);

  const [tasks, setTasks] = useState([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  // ----------------------------------------------------------------
  // On mount, fetch first batch of runs
  // ----------------------------------------------------------------
  useEffect(() => {
    if (user) {
      setOffset(0);
      fetchWorkflowRuns(0);
    }
  }, [user]);

  // ----------------------------------------------------------------
  // fetchWorkflowRuns - GET runs with pagination
  // ----------------------------------------------------------------
  const fetchWorkflowRuns = async (theOffset = 0) => {
    try {
      // 1) Get supabase token
      const { data } = await supabase.auth.getSession();
      const token = data.session?.access_token;
      if (!token) {
        console.error('No Supabase token found. Not logged in?');
        return;
      }

      // 2) GET /agent_workflows/workflow_runs?limit=10&offset=theOffset
      const limit = 10;
      const runsResp = await axios.get(
        `${API_BASE_URL}/agent_workflows/workflow_runs?limit=${limit}&offset=${theOffset}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const runsData = runsResp.data; // array of runs

      // 3) For each run, optionally fetch the workflow name
      const tasksTransformed = await Promise.all(
        runsData.map(async (run) => {
          const workflowName = await fetchWorkflowName(run.workflow_id, token);

          return {
            id: run.id,
            workflowName: workflowName || `Workflow ${run.workflow_id}`,
            status: run.status,
            startTime: run.started_at ? run.started_at : run.created_at,
            endTime: run.finished_at,
            phoneNumber: run.caller_phone_number || null,
            inputParameters: run.input_parameters || {},
            currentStep: `Step Index ${run.current_step_index}`,
            completedSteps: [],
            nextSteps: [],
            memoryLog: [],
            feedback: {
              rating: null,
              comments: ''
            },
            isExpanded: false
          };
        })
      );

      if (theOffset === 0) {
        setTasks(tasksTransformed);
      } else {
        setTasks((prev) => [...prev, ...tasksTransformed]);
      }

      // 4) If we got fewer than 'limit' items, there's no more to load
      setHasMore(tasksTransformed.length === limit);
    } catch (error) {
      console.error('Error fetching workflow runs:', error);
    }
  };

  // ----------------------------------------------------------------
  // "Load More" handler
  // ----------------------------------------------------------------
  const loadMoreRuns = () => {
    const nextOffset = offset + 10;
    setOffset(nextOffset);
    fetchWorkflowRuns(nextOffset);
  };

  // ----------------------------------------------------------------
  // fetchWorkflowName - GET /agent_workflows/workflows/:id
  // ----------------------------------------------------------------
  const fetchWorkflowName = async (workflowId, token) => {
    try {
      const resp = await axios.get(
        `${API_BASE_URL}/agent_workflows/workflows/${workflowId}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      return resp.data.name;
    } catch (error) {
      console.warn(`Could not fetch workflow name for ID=${workflowId}`, error);
      return null;
    }
  };

  // ----------------------------------------------------------------
  // onExpand task: fetch run detail (logs)
  // ----------------------------------------------------------------
  const toggleTaskExpansion = async (taskId) => {
    setTasks((prev) =>
      prev.map((t) => {
        if (t.id === taskId) {
          return { ...t, isExpanded: !t.isExpanded };
        }
        return t;
      })
    );

    const target = tasks.find((tsk) => tsk.id === taskId);
    if (!target || target.isExpanded) return; // if already expanded, no fetch needed

    try {
      const { data } = await supabase.auth.getSession();
      const token = data.session?.access_token;
      if (!token) {
        console.error('No Supabase token found. Not logged in?');
        return;
      }

      const runDetailResp = await axios.get(
        `${API_BASE_URL}/agent_workflows/workflow_runs/${taskId}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      const runDetail = runDetailResp.data;

      // Convert logs into an array of strings
      const logEntries = runDetail.logs.map((lg) => {
        return `${lg.timestamp} | [${lg.log_type}] -> ${JSON.stringify(lg.details)}`;
      });

      // Merge logs into the task
      setTasks((prev) =>
        prev.map((t) =>
          t.id === taskId
            ? { ...t, memoryLog: logEntries }
            : t
        )
      );
    } catch (err) {
      console.error('Error fetching run detail/logs:', err);
    }
  };

  // ----------------------------------------------------------------
  // Cancel a run
  // ----------------------------------------------------------------
  const handleCancelRun = async (taskId) => {
    try {
      const { data } = await supabase.auth.getSession();
      const token = data.session?.access_token;
      if (!token) {
        console.error('No Supabase token found. Not logged in?');
        return;
      }

      // POST /workflow_runs/{run_id}/cancel
      await axios.post(
        `${API_BASE_URL}/agent_workflows/workflow_runs/${taskId}/cancel`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      // Update status in local state to 'canceled'
      setTasks((prev) =>
        prev.map((t) =>
          t.id === taskId ? { ...t, status: 'canceled' } : t
        )
      );
    } catch (err) {
      console.error('Error canceling workflow run:', err);
    }
  };

  // ----------------------------------------------------------------
  // Feedback logic (purely local)
  // ----------------------------------------------------------------
  const handleFeedbackRating = (taskId, rating) => {
    setTasks((prev) =>
      prev.map((t) =>
        t.id === taskId
          ? { ...t, feedback: { ...t.feedback, rating } }
          : t
      )
    );
  };

  const handleFeedbackComments = (taskId, newComments) => {
    setTasks((prev) =>
      prev.map((t) =>
        t.id === taskId
          ? { ...t, feedback: { ...t.feedback, comments: newComments } }
          : t
      )
    );
  };

  const handleSubmitFeedback = (taskId) => {
    const task = tasks.find((t) => t.id === taskId);
    if (!task) return;
    console.log('Feedback submitted for Task:', taskId, task.feedback);
    alert(`Feedback submitted! [${task.feedback.rating}] ${task.feedback.comments}`);
    // Real app: call a POST to your backend
  };

  // ----------------------------------------------------------------
  // RENDER
  // ----------------------------------------------------------------
  return (
    <Box p={3}>
      <Card sx={{ backgroundColor: theme.palette.background.paper, boxShadow: 3, mb: 4 }}>
        <CardHeader
          avatar={<Avatar sx={{ bgcolor: theme.palette.secondary.main }}>O</Avatar>}
          title={<Typography variant="h5">Orchestration Tasks</Typography>}
          subheader={
            <Typography variant="body2" color="text.secondary">
              Below are past and current workflow runs, with status, agent info, input parameters, and logs.
            </Typography>
          }
        />
        <CardContent>
          {tasks.length === 0 && (
            <Typography>No active or past tasks found.</Typography>
          )}
          {tasks.map((task) => {
            const { label, icon, color } = getStatusVisual(task.status, theme);
            const isCompleted = task.status === 'completed';

            return (
              <Card
                key={task.id}
                sx={{
                  mb: 2,
                  backgroundColor: theme.palette.background.default,
                  boxShadow: 2,
                  border: '1px solid #444'
                }}
              >
                <CardHeader
                  title={
                    <Box display="flex" alignItems="center">
                      {icon}
                      <Typography variant="subtitle1" sx={{ mr: 1 }}>
                        {task.workflowName}
                      </Typography>
                      <Typography variant="body2" sx={{ color }}>
                        ({label})
                      </Typography>
                    </Box>
                  }
                  subheader={
                    <Typography variant="body2" color="text.secondary">
                      Started at{' '}
                      {task.startTime
                        ? new Date(task.startTime).toLocaleString()
                        : 'Unknown'}
                    </Typography>
                  }
                  action={
                    <ExpandMore
                      expand={task.isExpanded}
                      onClick={() => toggleTaskExpansion(task.id)}
                      aria-expanded={task.isExpanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  }
                />
                <Collapse in={task.isExpanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      <strong>Phone:</strong> {task.phoneNumber || 'N/A'}
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 1 }}>
                      <strong>Run Start Time:</strong>{' '}
                      {task.startTime
                        ? new Date(task.startTime).toLocaleString()
                        : 'N/A'}
                    </Typography>
                    {task.endTime && (
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        <strong>End Time:</strong>{' '}
                        {new Date(task.endTime).toLocaleString()}
                      </Typography>
                    )}

                    {/* Current Step */}
                    {task.currentStep && (
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        <strong>Current Step:</strong> {task.currentStep}
                      </Typography>
                    )}

                    {/* Input Parameters */}
                    <Box sx={{ mb: 2 }}>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
                      >
                        Input Parameters:
                      </Typography>
                      {task.inputParameters &&
                      Object.keys(task.inputParameters).length > 0 ? (
                        <Box sx={{ ml: 2, mt: 1 }}>
                          {Object.entries(task.inputParameters).map(([key, value]) => (
                            <Typography
                              variant="body2"
                              sx={{ color: '#ccc' }}
                              key={key}
                            >
                              <strong>{key}:</strong> {String(value)}
                            </Typography>
                          ))}
                        </Box>
                      ) : (
                        <Typography variant="body2" sx={{ ml: 2, color: '#ccc' }}>
                          No input parameters provided
                        </Typography>
                      )}
                    </Box>

                    {/* Completed Steps */}
                    {task.completedSteps && task.completedSteps.length > 0 && (
                      <Box sx={{ mb: 1 }}>
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
                        >
                          Completed Steps:
                        </Typography>
                        {task.completedSteps.map((step, idx) => (
                          <Chip
                            key={idx}
                            label={step}
                            sx={{
                              mr: 1,
                              mb: 1,
                              backgroundColor: '#333',
                              color: '#fff'
                            }}
                          />
                        ))}
                      </Box>
                    )}

                    {/* Next Steps */}
                    {task.nextSteps && task.nextSteps.length > 0 && (
                      <Box sx={{ mb: 2 }}>
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
                        >
                          Next Steps:
                        </Typography>
                        {task.nextSteps.map((step, idx) => (
                          <Chip
                            key={idx}
                            label={step}
                            sx={{
                              mr: 1,
                              mb: 1,
                              backgroundColor: '#333',
                              color: '#fff'
                            }}
                          />
                        ))}
                      </Box>
                    )}

                    {/* Memory/Action Log */}
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
                    >
                      Memory / Action Log:
                    </Typography>
                    <Box
                      sx={{
                        ml: 2,
                        mt: 1,
                        mb: 2,
                        p: 2,
                        backgroundColor: '#0f0f0f',
                        borderRadius: 1
                      }}
                    >
                      {task.memoryLog && task.memoryLog.length > 0 ? (
                        task.memoryLog.map((logEntry, i) => (
                          <Typography
                            key={i}
                            variant="body2"
                            sx={{ color: '#ccc' }}
                          >
                            {logEntry}
                          </Typography>
                        ))
                      ) : (
                        <Typography variant="body2" sx={{ color: '#ccc' }}>
                          No log entries yet.
                        </Typography>
                      )}
                    </Box>

                    {/* Cancel Button for pending/in_progress/running */}
                    {(task.status === 'pending' ||
                      task.status === 'in_progress' ||
                      task.status === 'running') && (
                      <Box sx={{ mb: 2 }}>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleCancelRun(task.id)}
                        >
                          Cancel
                        </Button>
                      </Box>
                    )}

                    {/* Feedback for completed tasks */}
                    {isCompleted && (
                      <Box sx={{ mt: 2 }}>
                        <Typography
                          variant="body1"
                          sx={{
                            mb: 1,
                            fontWeight: 'bold',
                            color: theme.palette.text.primary
                          }}
                        >
                          Targeted Learning Feedback
                        </Typography>
                        <Box display="flex" alignItems="center">
                          <IconButton
                            sx={{
                              color:
                                task.feedback.rating === 'up'
                                  ? (theme.palette.success?.main || '#4caf50')
                                  : 'inherit'
                            }}
                            onClick={() => handleFeedbackRating(task.id, 'up')}
                          >
                            <ThumbUpAltIcon />
                          </IconButton>
                          <IconButton
                            sx={{
                              color:
                                task.feedback.rating === 'down'
                                  ? (theme.palette.error?.main || '#f44336')
                                  : 'inherit'
                            }}
                            onClick={() => handleFeedbackRating(task.id, 'down')}
                          >
                            <ThumbDownAltIcon />
                          </IconButton>
                          {task.feedback.rating && (
                            <Typography variant="body2" sx={{ ml: 2 }}>
                              {task.feedback.rating === 'up' ? 'Thumbs Up' : 'Thumbs Down'}
                            </Typography>
                          )}
                        </Box>

                        <Collapse
                          in={Boolean(task.feedback.rating)}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box sx={{ mt: 2 }}>
                            <TextField
                              label="Additional Feedback"
                              multiline
                              rows={3}
                              variant="filled"
                              fullWidth
                              value={task.feedback.comments}
                              onChange={(e) =>
                                handleFeedbackComments(task.id, e.target.value)
                              }
                              sx={{ mb: 2 }}
                            />
                            <Button
                              variant="contained"
                              onClick={() => handleSubmitFeedback(task.id)}
                            >
                              Submit Feedback
                            </Button>
                          </Box>
                        </Collapse>
                      </Box>
                    )}
                  </CardContent>
                </Collapse>
              </Card>
            );
          })}

          {/* "Load More" button if we have more to load */}
          {hasMore && (
            <Box textAlign="center" mt={2}>
              <Button variant="contained" onClick={loadMoreRuns}>
                Load More
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default OrchestrationView;
