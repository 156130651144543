// src/components/SidebarDrawer.js
import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Box,
  SwipeableDrawer,
  useMediaQuery,
    Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const SidebarDrawer = ({
  drawerWidth,
  isMobile,
  mobileOpen,
  handleDrawerToggle,
  drawerContent,
}) => {
  return (
    <>
      {isMobile ? (
        <SwipeableDrawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          onOpen={handleDrawerToggle}
          ModalProps={{ keepMounted: true }} // Better open performance on mobile
        >
          {drawerContent}
          <Footer />
        </SwipeableDrawer>
      ) : (
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center" mt={2} mb={2}>
            <img
              src="/echobach_logo_192.png"
              alt="EchoBach Logo"
              style={{ width: 100, height: 100 }}
            />
          </Box>
          {drawerContent}
          <Footer />
        </Drawer>
      )}
    </>
  );
};

const Footer = () => (
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        textAlign: 'center',
        p: 2,
        backgroundColor: 'background.paper',
      }}
    >
      <Typography variant="body2" color="textSecondary">
        © 2025 EchoBach, Inc.
      </Typography>
      <Typography variant="body2" color="textSecondary">
        <Link to="/terms-of-service" style={{ color: 'inherit', textDecoration: 'none' }}>
          Terms of Service
        </Link>{' '}
        |{' '}
        <Link to="/privacy-policy" style={{ color: 'inherit', textDecoration: 'none' }}>
          Privacy Policy
        </Link>
      </Typography>
    </Box>
  );

export default SidebarDrawer;
