import React, { useState } from 'react';
import {
  Card, CardHeader, CardContent, CardActions, Avatar, Typography,
  TextField, Button, FormControl, InputLabel, Select, MenuItem,
  Box, Chip, IconButton, Collapse
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ expand }) => ({
  transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
}));

const CreateWorkflowForm = ({ tools, agents, onCreate }) => {
  const [workflowName, setWorkflowName] = useState('');
  const [primaryObjective, setPrimaryObjective] = useState('');
  const [assignedAgents, setAssignedAgents] = useState([]); // purely front-end
  const [steps, setSteps] = useState([]);
  const [expandedStepIndex, setExpandedStepIndex] = useState(null);

  // Add a new blank step
  const addStep = () => {
    setSteps((prev) => [
      ...prev,
      { stepName: '', stepObjective: '', selectedTools: [] },
    ]);
  };

  const removeStep = (index) => {
    setSteps((prev) => prev.filter((_, i) => i !== index));
  };

  const handleExpandStep = (index) => {
    setExpandedStepIndex((prev) => (prev === index ? null : index));
  };

  const handleStepChange = (index, field, value) => {
    setSteps((prev) =>
      prev.map((step, i) => (i === index ? { ...step, [field]: value } : step))
    );
  };

  const handleSave = () => {
    if (!workflowName) {
      alert('Please provide a workflow name.');
      return;
    }

    const newWorkflow = {
      id: 'wf-' + Date.now(),
      name: workflowName,
      primaryObjective,
      steps,
      assignedAgents,
    };

    onCreate(newWorkflow);

    // Reset form
    setWorkflowName('');
    setPrimaryObjective('');
    setSteps([]);
    setAssignedAgents([]);
    setExpandedStepIndex(null);
  };

  return (
    <Card sx={{ mb: 4, backgroundColor: '#1e1e1e', boxShadow: 3 }}>
      <CardHeader
        avatar={<Avatar sx={{ bgcolor: 'secondary.main' }}>N</Avatar>}
        title={<Typography variant="h5">Create a New Workflow</Typography>}
        subheader={
          <Typography variant="body2" color="text.secondary">
            Define your workflow’s name, objective, steps, and which agents can use it.
          </Typography>
        }
      />
      <CardContent>
        <TextField
          label="Workflow Name"
          variant="filled"
          fullWidth
          value={workflowName}
          onChange={(e) => setWorkflowName(e.target.value)}
          sx={{ mb: 3 }}
        />

        <TextField
          label="Primary Objective"
          variant="filled"
          fullWidth
          multiline
          rows={2}
          value={primaryObjective}
          onChange={(e) => setPrimaryObjective(e.target.value)}
          sx={{ mb: 3 }}
        />

        {/* Agent Assignment (purely front-end) */}
        <FormControl variant="filled" fullWidth sx={{ mb: 3 }}>
          <InputLabel id="agent-select-create">Assign Agents</InputLabel>
          <Select
            labelId="agent-select-create"
            multiple
            value={assignedAgents}
            onChange={(e) => setAssignedAgents(e.target.value)}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((assistantId) => {
                  const agentObj = agents.find((a) => a.assistant_id === assistantId);
                  const chipLabel = agentObj ? agentObj.assistant_name : assistantId;
                  return <Chip key={assistantId} label={chipLabel} />;
                })}
              </Box>
            )}
          >
            {agents.map((agent) => (
              <MenuItem key={agent.assistant_id} value={agent.assistant_id}>
                {agent.assistant_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography variant="h6" gutterBottom>Steps</Typography>
        {steps.map((step, index) => {
          const isExpanded = expandedStepIndex === index;
          return (
            <Card key={index} sx={{ mt: 2, backgroundColor: '#2a2a2a', boxShadow: 2 }}>
              <CardHeader
                /* CHANGED: Use 'Step {index}' instead of 'index + 1' */
                title={
                  <Typography variant="subtitle1">
                    Step {index}
                    {step.stepName ? ` - ${step.stepName}` : ''}
                  </Typography>
                }
                action={
                  <>
                    <IconButton color="error" onClick={() => removeStep(index)}>
                      <DeleteIcon />
                    </IconButton>
                    <ExpandMore
                      expand={isExpanded}
                      onClick={() => handleExpandStep(index)}
                      aria-expanded={isExpanded}
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </>
                }
              />
              <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <CardContent>
                  <TextField
                    label="Step Name"
                    variant="filled"
                    fullWidth
                    value={step.stepName}
                    onChange={(e) => handleStepChange(index, 'stepName', e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Step Objective"
                    variant="filled"
                    fullWidth
                    multiline
                    rows={2}
                    value={step.stepObjective}
                    onChange={(e) => handleStepChange(index, 'stepObjective', e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  <FormControl variant="filled" fullWidth>
                    <InputLabel id={`tool-select-label-${index}`}>Select Tools</InputLabel>
                    <Select
                      multiple
                      labelId={`tool-select-label-${index}`}
                      value={step.selectedTools}
                      onChange={(e) => handleStepChange(index, 'selectedTools', e.target.value)}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((toolName) => {
                            const toolObj = tools.find((t) => t.name === toolName);
                            const chipLabel = toolObj ? toolObj.display_name : toolName;
                            return <Chip key={toolName} label={chipLabel} />;
                          })}
                        </Box>
                      )}
                    >
                      {tools.map((tool) => (
                        <MenuItem key={tool.name} value={tool.name}>
                          {tool.display_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CardContent>
              </Collapse>
            </Card>
          );
        })}
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" startIcon={<AddIcon />} onClick={addStep} fullWidth>
            Add Step
          </Button>
        </Box>
      </CardContent>
      <CardActions>
        <Button variant="contained" color="primary" onClick={handleSave} fullWidth>
          Save Workflow
        </Button>
      </CardActions>
    </Card>
  );
};

export default CreateWorkflowForm;
