// src/components/EmailManagement.js

import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  TextField,
  Button,
  ListItemText,
  IconButton,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert
} from "@mui/material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import RefreshIcon from "@mui/icons-material/Refresh";
import SendIcon from "@mui/icons-material/Send";
import SortIcon from "@mui/icons-material/Sort";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";

// same token hook used in ContactsPage
import useAuthToken from "../hooks/useAuthToken";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const EmailManagement = () => {
  // ----------------------------------------------------------------
  // 1) Auth Token
  // ----------------------------------------------------------------
  const token = useAuthToken();

  // ----------------------------------------------------------------
  // 2) Component State
  // ----------------------------------------------------------------
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);

  const [emailAddress, setEmailAddress] = useState("");
  const [isEmailAvailable, setIsEmailAvailable] = useState(null);

  const [emails, setEmails] = useState([]);
  const [feedback, setFeedback] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("date");

  // Modal for viewing full email
  const [openModal, setOpenModal] = useState(false);
  const [modalEmail, setModalEmail] = useState(null);

  // Snackbar (similar to your ContactsPage)
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success"
  });

  // ----------------------------------------------------------------
  // 3) Effects
  // ----------------------------------------------------------------

  // A) Once we have a token, fetch the agents (with alias) from new endpoint
  useEffect(() => {
    if (token) {
      fetchAgentsWithAlias();
    }
  }, [token]);

  // B) Whenever selectedAgent changes, update the local alias text and fetch inbox
  useEffect(() => {
    if (selectedAgent) {
      setEmailAddress(selectedAgent.email_alias || "");
      fetchEmails(selectedAgent.assistant_id, searchTerm);
    }
  }, [selectedAgent]);

  // ----------------------------------------------------------------
  // 4) API Calls
  // ----------------------------------------------------------------

  const fetchAgentsWithAlias = async () => {
    try {
      const res = await axios.get(`${API_BASE_URL}/email_management/list_assistants_with_alias`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("Agents from /list_assistants_with_alias:", res.data);
      setAgents(res.data || []);
    } catch (error) {
      console.error("Error fetching agents with alias:", error);
      showSnackbar("Failed to fetch agents.", "error");
    }
  };

  const fetchEmails = async (assistantId, searchQuery = "") => {
    if (!assistantId) return;
    try {
      const res = await axios.get(`${API_BASE_URL}/email_management/get_emails`, {
        params: { agent_id: assistantId, search: searchQuery },
        headers: { Authorization: `Bearer ${token}` }
      });
      setEmails(res.data.messages || []);
    } catch (error) {
      console.error("Error fetching emails:", error);
      showSnackbar("Failed to fetch emails.", "error");
    }
  };

  const checkEmailAvailability = async () => {
    if (!emailAddress.trim()) {
      alert("Please enter a valid alias (without '@').");
      return;
    }
    try {
      const res = await axios.post(
        `${API_BASE_URL}/email_management/check_email`,
        { email: emailAddress },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setIsEmailAvailable(res.data.available);
      if (res.data.available) {
        alert("That alias is available! You can now create it.");
      } else {
        alert("That alias is already in use.");
      }
    } catch (error) {
      console.error("Error checking email availability:", error);
      setIsEmailAvailable(false);
    }
  };

  const handleEmailUpdate = async () => {
    if (isEmailAvailable !== true) {
      alert("Please check availability first, or the alias is not available.");
      return;
    }
    if (!selectedAgent) {
      alert("No agent selected.");
      return;
    }
    try {
      await axios.put(
        `${API_BASE_URL}/email_management/update_email_alias`,
        {
          agent_id: selectedAgent.assistant_id,
          email_alias: emailAddress
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      alert("Email address updated successfully.");

      // Refresh the agents so the updated alias is visible
      await fetchAgentsWithAlias();

      // Re-select the same agent
      const updated = agents.find((a) => a.assistant_id === selectedAgent.assistant_id);
      setSelectedAgent(updated || null);
    } catch (error) {
      console.error("Error updating email address:", error);
      alert("Failed to update email address.");
    }
  };

  // Thumbs up/down feedback
  const handleFeedback = async (emailItem, type) => {
    const newValue = feedback[emailItem.id] === type ? null : type; // toggle
    setFeedback((prev) => ({ ...prev, [emailItem.id]: newValue }));

    try {
      await axios.post(
        `${API_BASE_URL}/email_management/emails/${emailItem.id}/set_feedback`,
        { feedback: newValue, feedback_notes: null },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (err) {
      console.error("Error saving feedback:", err);
      showSnackbar("Failed to save feedback.", "error");
    }
  };

  // Re-fetch inbox
  const handleSyncEmails = () => {
    if (selectedAgent) {
      fetchEmails(selectedAgent.assistant_id, searchTerm);
    }
  };

  // Send a test email
  const handleSendTestEmail = async () => {
    if (!selectedAgent) return;
    try {
      await axios.post(
        `${API_BASE_URL}/email_management/send_test_email`,
        { agent_id: selectedAgent.assistant_id },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert("Test email sent successfully.");
    } catch (error) {
      console.error("Error sending test email:", error);
      alert("Failed to send test email.");
    }
  };

  // Search
  const handleSearch = () => {
    if (selectedAgent) {
      fetchEmails(selectedAgent.assistant_id, searchTerm);
    }
  };

  // Open email modal
  const handleOpenModal = async (emailId) => {
    try {
      const res = await axios.get(`${API_BASE_URL}/email_management/emails/${emailId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setModalEmail(res.data);
      setOpenModal(true);
    } catch (err) {
      console.error("Error fetching full email:", err);
      showSnackbar("Failed to fetch email details.", "error");
    }
  };

  const handleCloseModal = () => {
    setModalEmail(null);
    setOpenModal(false);
  };

  // Delete email
  const handleDeleteEmail = async (emailId) => {
    if (!window.confirm("Are you sure you want to delete this email?")) return;
    try {
      await axios.delete(`${API_BASE_URL}/email_management/emails/${emailId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setEmails((prev) => prev.filter((e) => e.id !== emailId));
    } catch (err) {
      console.error("Error deleting email:", err);
      showSnackbar("Failed to delete email.", "error");
    }
  };

  // ----------------------------------------------------------------
  // 5) Sorting
  // ----------------------------------------------------------------
  const sortedEmails = [...emails].sort((a, b) => {
    if (sortBy === "date") {
      return new Date(b.timestamp) - new Date(a.timestamp);
    } else if (sortBy === "sender") {
      return (a.from_email || "").localeCompare(b.from_email || "");
    } else if (sortBy === "subject") {
      return (a.subject || "").localeCompare(b.subject || "");
    }
    return 0;
  });

  // ----------------------------------------------------------------
  // 6) Handlers
  // ----------------------------------------------------------------
  const handleAgentChange = (event) => {
    const agentId = event.target.value;
    const newAgent = agents.find((a) => a.assistant_id === agentId);
    setSelectedAgent(newAgent || null);
    setIsEmailAvailable(null);
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  // ----------------------------------------------------------------
  // 7) Snackbar helpers (like in ContactsPage)
  // ----------------------------------------------------------------
  const showSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // ----------------------------------------------------------------
  // 8) Render
  // ----------------------------------------------------------------
  return (
    <>
      <Card sx={{ mb: 3, backgroundColor: "#1e1e1e", boxShadow: 3 }}>
        <CardHeader
          avatar={<Avatar sx={{ bgcolor: "secondary.main" }}>E</Avatar>}
          title={<Typography variant="h5">Email Bach</Typography>}
          subheader={
            <Typography variant="body2" color="text.secondary">
              Configure an agent’s email address, review inbox, and manage email actions.
            </Typography>
          }
        />
        <CardContent>
          {/* Agent + Alias Config */}
          <Card sx={{ mb: 3, backgroundColor: "#2a2a2a", boxShadow: 2 }}>
            <CardHeader
              title={<Typography variant="h6">Assign & Configure Email Alias</Typography>}
              subheader={
                <Typography variant="body2" color="text.secondary">
                  Select an agent and set an alias for their email address.
                  Please enter only the alias (no '@').
                </Typography>
              }
            />
            <CardContent>
              <FormControl fullWidth variant="filled" sx={{ mb: 2 }}>
                <InputLabel>Select Agent</InputLabel>
                <Select
                  value={selectedAgent?.assistant_id || ""}
                  onChange={handleAgentChange}
                >
                  {agents.length === 0 ? (
                    <MenuItem disabled>No agents found</MenuItem>
                  ) : (
                    agents.map((agent) => (
                      <MenuItem key={agent.assistant_id} value={agent.assistant_id}>
                        {agent.assistant_name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>

              {selectedAgent && (
                <>
                  <Typography variant="subtitle2" sx={{ mt: 2 }}>
                    {selectedAgent.email_alias
                      ? `Currently set to: ${selectedAgent.email_alias}@mail.echobach.com`
                      : "No alias set yet. Enter desired alias below."}
                  </Typography>

                  <TextField
                    label="Email Alias (no '@')"
                    variant="filled"
                    fullWidth
                    value={emailAddress}
                    onChange={(e) => {
                      const cleaned = e.target.value.replace(/[^a-zA-Z0-9._-]/g, "");
                      setEmailAddress(cleaned);
                      setIsEmailAvailable(null);
                    }}
                    sx={{ mt: 2 }}
                    helperText={
                      isEmailAvailable === false
                        ? "This alias is not available."
                        : isEmailAvailable === true
                        ? "This alias is available!"
                        : "Enter an alias and click 'Check Availability'."
                    }
                    error={isEmailAvailable === false}
                  />

                  <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={checkEmailAvailability}
                    >
                      Check Availability
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleEmailUpdate}
                    >
                      Create/Update Email Alias
                    </Button>
                  </Box>
                </>
              )}

              {!selectedAgent && (
                <Typography variant="body2" sx={{ mt: 2 }}>
                  Select an agent to manage email settings.
                </Typography>
              )}
            </CardContent>
          </Card>

          {/* Email Actions (Sync, Test Send, Search) */}
          {selectedAgent && (
            <Card sx={{ mb: 3, backgroundColor: "#2a2a2a", boxShadow: 2 }}>
              <CardHeader
                title={<Typography variant="h6">Email Actions</Typography>}
                subheader={
                  <Typography variant="body2" color="text.secondary">
                    Sync the inbox or send a test email to verify configuration.
                  </Typography>
                }
              />
              <CardContent>
                <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<RefreshIcon />}
                    onClick={handleSyncEmails}
                  >
                    Sync Emails
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<SendIcon />}
                    onClick={handleSendTestEmail}
                  >
                    Send Test Email
                  </Button>
                </Box>

                {/* Search box */}
                <Box sx={{ display: "flex", gap: 2 }}>
                  <TextField
                    variant="filled"
                    label="Search Emails"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ flex: 1 }}
                  />
                  <Button variant="contained" onClick={handleSearch}>
                    Search
                  </Button>
                </Box>
              </CardContent>
            </Card>
          )}

          {/* Inbox */}
          {selectedAgent && (
            <Card sx={{ backgroundColor: "#2a2a2a", boxShadow: 2 }}>
              <CardHeader
                title={<Typography variant="h6">Inbox / Sent</Typography>}
                subheader={
                  <Typography variant="body2" color="text.secondary">
                    Review incoming and outgoing emails for this agent.
                  </Typography>
                }
                action={
                  <FormControl variant="standard" sx={{ mr: 2, mt: 1 }}>
                    <InputLabel>
                      <SortIcon sx={{ mr: 1, verticalAlign: "middle" }} /> Sort By
                    </InputLabel>
                    <Select value={sortBy} onChange={handleSortChange}>
                      <MenuItem value="date">Date</MenuItem>
                      <MenuItem value="sender">Sender</MenuItem>
                      <MenuItem value="subject">Subject</MenuItem>
                    </Select>
                  </FormControl>
                }
              />
              <CardContent>
                {sortedEmails.length === 0 ? (
                  <Typography variant="body2" color="text.secondary">
                    No emails to display.
                  </Typography>
                ) : (
                  sortedEmails.map((email) => (
                    <Box
                      key={email.id}
                      sx={{
                        mb: 2,
                        p: 1,
                        borderRadius: 1,
                        backgroundColor: "#121212",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "space-between"
                      }}
                    >
                      <ListItemText
                        primary={
                          <>
                            <Typography
                              variant="subtitle2"
                              sx={{ cursor: "pointer" }}
                              onClick={() => handleOpenModal(email.id)}
                            >
                              {email.subject || "No subject"}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              From: {email.from_email} |{" "}
                              {new Date(email.timestamp).toLocaleString()}
                            </Typography>
                          </>
                        }
                        secondary={
                          <Typography variant="body2" color="text.secondary">
                            {email.snippet || "No preview available"}
                          </Typography>
                        }
                      />
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          onClick={() => handleFeedback(email, "up")}
                          sx={{
                            color: feedback[email.id] === "up" ? "green" : "inherit"
                          }}
                        >
                          <ThumbUpAltIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleFeedback(email, "down")}
                          sx={{
                            color: feedback[email.id] === "down" ? "red" : "inherit"
                          }}
                        >
                          <ThumbDownAltIcon />
                        </IconButton>
                        <IconButton color="error" onClick={() => handleDeleteEmail(email.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  ))
                )}
              </CardContent>
            </Card>
          )}
        </CardContent>
        <CardActions />
      </Card>

      {/* Modal for Full Email */}
      <Dialog open={openModal} onClose={handleCloseModal} fullWidth maxWidth="md">
        <DialogTitle>Email Details</DialogTitle>
        <DialogContent dividers>
          {modalEmail ? (
            <>
              <Typography variant="body2">
                <strong>From:</strong> {modalEmail.from_email}
              </Typography>
              <Typography variant="body2">
                <strong>To:</strong> {modalEmail.to_email}
              </Typography>
              <Typography variant="body2">
                <strong>Subject:</strong> {modalEmail.subject || "No subject"}
              </Typography>
              <Typography variant="body2">
                <strong>Date:</strong> {new Date(modalEmail.timestamp).toLocaleString()}
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
                  {modalEmail.body || "No content"}
                </Typography>
              </Box>
            </>
          ) : (
            <Typography>Loading...</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* SNACKBAR (like in ContactsPage) */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EmailManagement;
